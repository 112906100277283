import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Footer from "../../../components/Footer";
import WhatsappChat from "../../../components/WhatsappChat";
import Bike from "../../../images/products/adBikeUrdu.png";
import { localStorageKeys } from "../../../utility/localstorage";
import { getTranslatedText } from "../../../utility/constant";
import ProductPrefernceBannerUrdu from "../../../components/ProductPrefernceBannerUrdu";

const BikesPageIndex = ({}) => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <ProductPrefernceBannerUrdu
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne="ماہانہ قسط پر"
        markHeadline="Rs 6,037"
        headlineTwo="Honda CD 70 اپنی"
        headlineThree="چلائیں"
        descpOne="کریڈٹ پر كے پاس آپ كے لیے بہترین پلانز موجود ہیں"
        descpTwo="!چلائیں  Honda CD-70 آسان قدم پورے کریں اور ابھی اپنی"
        bikeImage={Bike}
      />

      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default BikesPageIndex;
